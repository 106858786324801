.link_style{
    text-decoration: none;
    color: black;
}
.footer_content{
    display: flex;
    justify-content: flex-end;
}

.email_input{
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid gray;
}
.cards_parent_backgorund{
    background-color: #F7F7F7;
    padding: 10px 80px;
}
@media screen and (min-width:1500px) {
    .cards_parent_backgorund{
        background-color: #F7F7F7;
        padding: 10px 300px;
    }
}
.card_header{
    border-left: 6px solid #7FDAD2;
    padding-left: 12px;
    margin-bottom: 20px;
}
.viewAll_button{
    text-decoration: none;
    color: #0B655D;
    font-weight: 800;
}
.read_more{
    border: 2px solid #91DFD8;
    color: black;
    text-decoration: none;
}
.read_more:hover{
    background-color: #91DFD8;
    color: black;
    border: none;
}
.card{
    background-color: hsl(0, 0%, 100%);
}

.what_card{
    background: #F7F7F7;
}
.read_more_btn{
    text-decoration: none;
    color: black;
}

.content_div{
    width: 500px;
    height: auto;
    padding: 30px;
    background-color: #7FDAD2;
    border-radius: 10px;
    margin: 130px;
}
.bg_video {
    position: relative;
}

.bg_video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}
.watch_video_btn{
    background-color: black;
    color: white;
    border-radius: 30px;
    border: none;
    padding: 10px;
    width: 150px;
}
.opacityD{
    background-color: red;
    z-index: 9;
}


.get_started_btn{
    border-radius: 30px;
    background-color: #7FDAD2;
    color: black;
    border: none;
    padding: 7px 18px;
}
.get_started_btn:hover{
    background-color: #7FDAD2;
    color: black;
}
@media screen and (min-width:450px) {
    .content_div{
        margin: 25px;
    }
}